
import {
  IonPage, IonContent, IonList, IonItem, IonHeader,
  IonToolbar, IonButtons, IonButton, IonIcon, IonTitle,
} from '@ionic/vue';
import { menuCategories } from "@/config/StaticData";
import { defineComponent } from "vue";
import { searchOutline } from "ionicons/icons";

export default defineComponent({
  name: 'FoodMenu',
  components: {
    IonPage, IonContent, IonList, IonItem, IonHeader,
    IonToolbar, IonButtons, IonButton, IonIcon, IonTitle,
  },
  setup() {
    return {
      categories: menuCategories,
      searchOutline
    }
  }
})
