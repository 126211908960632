<template>
  <ion-page>
    <ion-header class="background-light">
      <ion-toolbar color="primary">
        <ion-title class="ion-text-capitalize">Carta</ion-title>
        <ion-buttons slot="end">
          <ion-button routerDirection="forward" router-link="/carta/buscar">
            <ion-icon :icon="searchOutline"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="background-light">
      <ion-list lines="full" class="spaced bordered">
        <ion-item button routerDirection="forward"
                  router-link="/carta/destacados">
          <p>Destacados</p>
        </ion-item>
      </ion-list>
      <ion-list lines="full" class="spaced bordered">
        <ion-item button
                  v-for="category in categories" :key="category.id"
                  routerDirection="forward"
                  :router-link="'/carta/' + category.slug">
          <p>{{ category.name }}</p>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage, IonContent, IonList, IonItem, IonHeader,
  IonToolbar, IonButtons, IonButton, IonIcon, IonTitle,
} from '@ionic/vue';
import { menuCategories } from "@/config/StaticData";
import { defineComponent } from "vue";
import { searchOutline } from "ionicons/icons";

export default defineComponent({
  name: 'FoodMenu',
  components: {
    IonPage, IonContent, IonList, IonItem, IonHeader,
    IonToolbar, IonButtons, IonButton, IonIcon, IonTitle,
  },
  setup() {
    return {
      categories: menuCategories,
      searchOutline
    }
  }
})
</script>
